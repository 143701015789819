<template>

  <div class="row" v-if="commande">
    <div class="col-lg-8">
       <div class="ibox">
        <div class="ibox-content">
          <h1>Commande #{{ commande.reference }}</h1>
          <strong>Prix total :</strong> {{ commande.prix_total }} €<br />
          <strong>Prix total TTC:</strong> {{ commande.prix_total_ttc }} €<br />
          <strong>Step :</strong> {{ commande.step }}<br />
          <h2>Items</h2>
          <div v-for="item in commande.items" :key="item.id">
            {{ item.libelle }} x {{ item.quantity }}
          </div>
          <div v-if="commande.client">
            <h2>Client</h2>
            {{ commande.client.prenom}} {{ commande.client.nom}} {{ commande.client.telephone}} {{ commande.client.email}}
            <h3>Adresse Livraison</h3>
            {{ commande.client.adresse_livraison.street }}<br />
            {{ commande.client.adresse_livraison.city }}<br />
            {{ commande.client.adresse_livraison.postal_code }}
            <h3>Adresse Facturation</h3>
            {{ commande.client.adresse_facturation.street }}<br />
            {{ commande.client.adresse_facturation.city }}<br />
            {{ commande.client.adresse_facturation.postal_code }}
          </div>
          
          
        </div>
       </div>
    </div> 
    <div class="col-lg-4" v-if="commande.step == 'paid'">
        <div class="ibox">
          <div class="ibox-content">
            <h2>Factures</h2>
            <button class="btn btn-default" @click="voirFacture('client')">Facture Client</button>
            <button class="btn btn-default" @click="voirFacture('vendeur')">Facture Vendeur</button>
          </div>
        </div>
       <div class="ibox">
        <div class="ibox-content" v-if="commande.offre && commande.offre.type.has_produit">
          <h2>Livraison</h2>
          <div v-if="commande.chronopost_shipping">
            {{ commande.mode_livraison }}
            {{ commande.chronopost_shipping.geo_post_numero_colis }}
            <button class="btn btn-default" @click="voirTicket()">Télécharger le ticket</button>
          </div>
          <div v-else>
            <button class="btn btn-default" @click="generateTicket()">Générer le ticket</button>
          </div>
           
          
        </div>
       </div>
    </div>    
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Commande",
  data() {
    return {
      commande: {},
    };
  },
  validations() {
      return {
        commande: {},
      }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    // $route: "fetchData",
  },
  methods: {
    fetchData() {
      
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });  
      axios
        .get(
          process.env.VUE_APP_API + "/producteur/commande/" +
            this.$route.params.uuid,
          {}
        )
        .then((resp) => {          
          loading.close();
          this.commande = resp.data;          
        })
        .catch((error) => {
          console.log("ERROR", error);
        });
    },
    voirFacture(type) {
          axios
            .get(process.env.VUE_APP_API + "/producteur/commande/facture/" + this.commande.uuid + '/' + type, {
                responseType: 'arraybuffer'
            })
            .then((resp) => {                
                const blob = new Blob([resp.data], { type: 'application/pdf' }),
                url = window.URL.createObjectURL(blob)
                window.open(url) 
            })
            .catch((error) => {
                console.log('ERROR', error)
            });
      },
      generateTicket() {
          axios
            .get(process.env.VUE_APP_API + "/producteur/commande/chronopost/generate/" + this.commande.uuid , {
                responseType: 'arraybuffer'
            })
            .then((resp) => {                
                this.fetchData();
                const blob = new Blob([resp.data], { type: 'application/pdf' }),
                url = window.URL.createObjectURL(blob)
                window.open(url) 
            })
            .catch((error) => {
                console.log('ERROR', error)
            });
      },
      voirTicket() {
          axios
            .get(process.env.VUE_APP_API + "/producteur/commande/chronopost/" + this.commande.uuid , {
                responseType: 'arraybuffer'
            })
            .then((resp) => {                
                const blob = new Blob([resp.data], { type: 'application/pdf' }),
                url = window.URL.createObjectURL(blob)

                window.open(url) 
            })
            .catch((error) => {
                console.log('ERROR', error)
            });
      }
  }
};
</script>